const $ = window.$

// When modifying the constant below, make sure to update it in Zusatzinformation.php as well.
const ACCREDITED_OTHER_REASON = 'Other reason'
// When modifying the constant below, make sure to update it in Kursdaten.php as well.
const STUDY_MODE_FULL_ONLINE = '1'
const HELP_TEXT_CRITERIA_CHECK = 'Neben der Zielgruppe müssen mindestens drei weitere Kriterien erfüllt werden. Sie können aus "Further criteria of the DAAD" und "Further criteria of the DAAD - Services" gewählt werden.'
const HELP_TEXT_CRITERIA_CHECK_ONLINE = 'Neben der Zielgruppe müssen hier die Vergabe von ECTS-Punkten und bei "Criteria for online programmes" die vier weitere Kriterien erfüllt werden. Bei Nicht-Erfüllung der ECTS-Punkte müssen bei "Criteria for online programmes" fünf Kriterien erfüllt werden.'
// When modifying the constant below, make sure to update it in Kurs.php as well.
const LONG_SHORT_COURSE_NAME = 'Language course/short course'

const updateCourseIsAccredited = (inputField) => {
    updateTextInCourseIsAccredited(inputField)
    inputField.on('change', function () {
        updateTextInCourseIsAccredited(inputField)
    })
}

const updateTextInCourseIsAccredited = (inputField) => {
    if (inputField.val() === ACCREDITED_OTHER_REASON) {
        inputField.parent().append('<div class="help-block sonata-ba-field-error-messages"><ul class="list-unstyled"><li><i class="mdi mdi-alert-circle"></i> Bei der Auswahl "No, other reason" ist das Kriterium der Akkreditierung nicht erfüllt. Bitte überprüfen Sie Ihre Angaben oder wenden Sie sich an: <a href="mailto:i-p@content5.de">i-p@content5.de</a>.</li></ul>')
        inputField.closest('div.form-group').addClass('has-error')
        inputField.parent().find('div.select2-container').addClass('update-field-has-error')
    } else {
        inputField.parent().find('.sonata-ba-field-error-messages').remove()
        inputField.closest('div.form-group').removeClass('has-error')
        inputField.parent().find('div.select2-container').removeClass('update-field-has-error')
    }
}

const updateStudyModes = (inputField) => {
    const studyOnline = $('select[name*="studyOnline"]')
    const courseType = $('select[name*="kursart"]')
    if (courseType.val() === LONG_SHORT_COURSE_NAME) {
        updateTextByStudyModes(inputField, studyOnline)
        inputField.on('change', function () {
            updateTextByStudyModes(inputField, studyOnline)
        })
        studyOnline.on('change', function () {
            updateTextByStudyModes(inputField, studyOnline)
        })
    }
}

const updateTextByStudyModes = (inputField, studyOnline) => {
    const criteriaCheck = $('ul[id$="criteriaCheck"]')
    const helpText = criteriaCheck.closest('.js-form-row').find('span.help-block')
    if (inputField.val() === STUDY_MODE_FULL_ONLINE && studyOnline.val() === '1') {
        helpText.text(HELP_TEXT_CRITERIA_CHECK_ONLINE)
    } else {
        helpText.text(HELP_TEXT_CRITERIA_CHECK)
    }
}

const updateFields = {
    courseIsAccredited: $('select[name*="courseIsAccredited"]'),
    studyModes: $('select[name*="studyModes"]'),
    init () {
        updateCourseIsAccredited(this.courseIsAccredited)
        updateStudyModes(this.studyModes)
    },
}
export default updateFields
